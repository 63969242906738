/*.carousel .carousel-item img {*/
/*    max-width: 800px;*/
/*    height: 400px;*/
/*}*/

.slider-wrapper {
    height: auto;
    /*max-height: 600px;*/
    width: 100%;
    max-width: 800px; /* установите желаемую максимальную ширину слайдера */
    margin: 0 auto;
}
img {
    height: auto;
    max-height: 700px;
    max-width: 800px
}

.slider-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* соотношение сторон 16:9 (9/16 * 100%) */
    overflow: hidden;
}

.slider-container .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text-container {
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-content {
    text-align: center;
}

@media (max-width: 768px) {
    .videclass {
        width: 90%;
    }
}